<template>
  <div id="mainColumn1">
    <top3-panel v-if="top3Stories" :stories="top3Stories"></top3-panel>
    <story-list-panel :stories="stories"></story-list-panel>
  </div>
</template>

<script lang="ts">
import Top3Panel from '@/components/news-network/Top3Panel.vue';
import StoryListPanel from '@/components/news-network/StoryListPanel.vue';
import { STORIES } from '@/stories/index';
import { CATEGORIES } from '@/stories/categories';
import { useRoute } from 'vue-router';
import { computed, defineComponent } from '@vue/runtime-core';

export default defineComponent({
  setup() {
    let route = useRoute();
    let key = computed(() => route.params.key as string);
    let category = computed(() => CATEGORIES[key.value]);
    let storyIDs = computed(() => (category) ? category.value.storyIDs : []);
    let stories = computed(() => storyIDs.value.map((id) => STORIES[id]));
    let top3StoryIDs = computed(() => category.value?.top3StoryIDs);
    let top3Stories = computed(() => top3StoryIDs.value?.map((id) => STORIES[id]));
    return { stories, top3Stories };
  },
  components: {
    Top3Panel,
    StoryListPanel,
  }
});
</script>
